import resful from "../../services/resful.js";

export const serviceReview = {
	async getListTemplate(data, next) {
		try {
			let res = await resful.get("/app/templates?category="+data.idCate+"&limit="+data.limit+"&status="+data.status, data);
			next(res)
		} catch (e) {
			console.log(e)
		}
	},
	async getListCategory(data, next) {
		try {
			let res = await resful.get("/app/category?type=marketplace&&type_second="+data.type_second, data);
			next(res)
		} catch (e) {
			console.log(e)
		}
	},
	async postUpdateTemplate(data, next) {
		try {
			let res = await resful.post("/app/update_template", data);
			next(res)
		} catch (e) {
			console.log(e)
		}
	},
	async postRemoveTemplate(data, next) {
		try {
			let res = await resful.post("/app/remove_template_private", data);
			next(res)
		} catch (e) {
			console.log(e)
		}
	},
	getCateAndTemp: async function (data, next) {
		let res = await resful.post("/app/get_templates_and_category", data)
		next(res);
	}
}