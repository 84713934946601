import { serviceReview } from "./review-template.service.js";
import { renderBlockEditor } from "@/libs-core/render-block-editor.min.js";
let setStyle = require("@/libs-core/set-style-block.min.js");
export default {
    data() {
        return {
            listTemplate: [],
            listCategory: [],
            activeContent: "app",
            listBgColor: [
                "linear-gradient(126deg, rgba(231,221,255,1) 0%, rgba(200,179,236,1) 100%)",
                "linear-gradient(126deg, rgba(76,161,175,1) 0%, rgba(196,224,229,1) 100%)",
                "linear-gradient(126deg, rgba(189,195,199,1) 0%, rgba(44,62,80,1) 100%)",
                "linear-gradient(126deg, rgba(238,205,163,1) 0%, rgba(239,98,159,1) 100%)",
                "linear-gradient(to bottom, #acb6e5, #86fde8)",
                "linear-gradient(to bottom, #ffefba, #ffffff)",
                "linear-gradient(to bottom, #c9d6ff, #e2e2e2)",
                "linear-gradient(to right, #d9a7c7, #fffcdc)",
                "linear-gradient(to right, #1c92d2, #f2fcfe)"
            ],
            linkPreview: "#",
            timeOutId: "",
            typeSecond: "app",
            idCategory: "",
            previewApp: 0,
            statusTem: "unactive",
            allTemplate: []
        };
    },
    // return item.status === "unactive" && item.type === this.activeContent && item.category;
    computed: {
        listTemplateUnactive() {
            return this.listTemplate
                .filter((item) => {
                    return item.status === "unactive" && item.type === this.activeContent && item.category;
                })
                .map((item) => {
                    let indexColor = Math.round(Math.random() * 4);
                    let color = this.listBgColor[indexColor];
                    item.backgroundCss = color;
                    return item;
                });
        }
    },
    watch: {
        linkPreview(val) {
            if (val === "#") {
                $("#review-template .iframe-section iframe").css("opacity", "");
                $("#review-template .iframe-section p").css("display", "");
                clearTimeout(this.timeOutId);
                return;
            }
            let timeOutId = setTimeout(function () {
                $("#review-template .iframe-section iframe").css("opacity", 1);
                $("#review-template .iframe-section p").hide();
            }, 3000);
            this.timeOutId = timeOutId;
        },
        typeSecond: function () {
            this.getListCategory();
        },
    },
    methods: {
        changeCate: function (val) {
            this.idCategory = val;
            this.getListTemplate();
        },
        computeCategory(id) {
            if (!id) return;
            console.log('id', id)
            return this.listCategory.find((item) => id === item.id);
        },
        computeDes(des) {
            des = JSON.parse(des).description;
            if (!des.length) {
                return "No description";
            }
            return des;
        },
        openPreview(item) {
            console.log("item:: ", item);
            this.previewApp = 1;
            if (item.type !== "app") {
                var arrApi = item.source.api;
                var html = "";
                $(".content-preview-section").html(html);
                var device = 'mobile';
                $(".iframe-section .loading").hide();
                $(".iframe-section .content-preview-app").hide();
                $(".iframe-section .content-preview-section").show();
                for (var i = 0; i < arrApi.length; i++) {
                    html = "";
                    console.log("arrApi[i]:: ", arrApi[i]);
                    switch (arrApi[i].componentName) {
                        case "blockButton":
                            html += renderBlockEditor.renderBlockButton(arrApi[i], device);
                            break;
                        case "blockCheckbox":
                            html += renderBlockEditor.renderBlockCheckbox(arrApi[i], device);
                            break;
                        case "blockColumnOnly":
                            html += renderBlockEditor.renderBlockColumnOnly(arrApi[i], device);
                            break;
                        case "blockHtml":
                            html += renderBlockEditor.renderBlockHtml(arrApi[i], device);
                            break;
                        case "blockImage":
                            html += renderBlockEditor.renderBlockImage(arrApi[i], device);
                            break;
                        case "blockInput":
                            html += renderBlockEditor.renderBlockInput(arrApi[i], device);
                            break;
                        case "blockList":
                            html += renderBlockEditor.renderBlockList(arrApi[i], device);
                            break;
                        case "blockRadio":
                            html += renderBlockEditor.renderBlockRadio(arrApi[i], device);
                            break;
                        case "blockSelect":
                            html += renderBlockEditor.renderBlockSelect(arrApi[i], device);
                            break;
                        case "blockText":
                            html += renderBlockEditor.renderBlockText(arrApi[i], device);
                            break;
                        case "blockTextarea":
                            html += renderBlockEditor.renderBlockTextArea(arrApi[i], device);
                            break;
                        case "blockVideo":
                            html += renderBlockEditor.renderBlockVideo(arrApi[i], device);
                            break;
                        case "blockSvg":
                            html += renderBlockEditor.renderBlockSvg(arrApi[i], device);
                            break;
                        case "blockLightBox":
                            html += renderBlockEditor.renderBlockLightBox(arrApi[i], device);
                            break;
                        case "blockGroupTmp":
                            html += renderBlockEditor.renderBlockGroupTmp(arrApi[i], device);
                            break;
                        case "blockGroup":
                            html += renderBlockEditor.renderBlockGroup(arrApi[i], device);
                            break;
                        case "blockCountdown":
                            html += renderBlockEditor.renderBlockCountdown(arrApi[i], device);
                            break;
                        case "blockForm":
                            html += renderBlockEditor.renderBlockForm(arrApi[i], device);
                            break;
                    }
                    if (arrApi[i].idPar && arrApi[i].idPar.length > 0 && arrApi.length > 1) {
                        $(".content-preview-section #" + arrApi[i].idPar + " > .block-content").append(html);
                    } else {
                        $(".content-preview-section").append(html);
                    }
                    if (i == (arrApi.length - 1)) {
                        if (arrApi.length != 1) {
                            renderBlockEditor.renderSortChild(arrApi, device);
                        }
                        setStyle.init(arrApi, device);
                        $(".add-column").hide();
                    }
                }
            } else {
                $(".content-preview-section").hide();
                if (!item.description) {
                    this.linkPreview = "#";
                }
                let linkPreview = JSON.parse(item.description).linkDemo;
                if ((!linkPreview || linkPreview === null) && item.id) {
                    linkPreview = `https://app.appon.vn/${item.id}`;
                }
                if (this.activeContent !== "app" || !linkPreview) {
                    this.linkPreview = "#";
                }
                this.linkPreview = linkPreview;
                window.open(linkPreview, "_blank")
                this.previewApp = 0;
            }

        },
        changeCateStatus: function(val) {
            this.listTemplate = [];
            this.statusTem = val;
            // for(var i = 0; i < this.allTemplate.length; i++) {
            //     if (this.allTemplate[i].status === this.statusTem) {
            //         this.listTemplate.push(this.allTemplate[i])
            //     }
            // }
            this.getListTemplate();
        },
        getListTemplate() {
            let thisVue = this;
            this.listTemplate = [];
            this.allTemplate = []
            serviceReview.getListTemplate({idCate: this.idCategory, limit: 500, status: thisVue.statusTem}, function (res) {
                if (!res || !res.data) return;

                thisVue.listTemplate = res.data;
            });
            // serviceReview.getCateAndTemp(
            //     JSON.stringify({
            //         cate_query: {
            //             type: "marketplace",
            //             type_second: this.typeSecond
            //         },
            //         temp_limit: 100,
            //         owner_query: {
            //             type: this.typeSecond
            //         },
            //         owner_limit: 100
            //     }),
            //     (data) => {
            //         var listTem = data.data.find((x) => x.id === this.idCategory).templates;
            //         thisVue.allTemplate = $.extend(true, [], listTem);
            //         for (var i = 0; i < listTem.length; i++) {
            //             if (listTem[i].status === this.statusTem) {
            //                 thisVue.listTemplate.push(listTem[i])
            //             }
            //         }
            //     }
            // );
        },
        getListCategory() {
            let thisVue = this;

            serviceReview.getListCategory({ type_second: this.typeSecond }, function (res) {
                if (!res || !res.data) return;
                thisVue.idCategory = res.data[0].id;
                thisVue.listCategory = res.data;
                thisVue.getListTemplate();
            });
        },
        activeTemplate(id) {
            let thisVue = this;

            this.$swal({
                title: "Active Template",
                text: "This action is not reverse",
                type: "question",
                showCancelButton: true
            }).then((r) => {
                if (r && r.value) {
                    serviceReview.postUpdateTemplate(JSON.stringify({ id: id, status: "active" }), function (res) {
                        if (!res || !res.data || !res.data.length) {
                            thisVue.$swal({
                                title: "Active template error",
                                type: "error",
                                showConfirmButton: false
                            });
                            return;
                        }

                        thisVue.getListTemplate();
                        thisVue.$swal({
                            title: "Active template success",
                            type: "success",
                            showConfirmButton: false
                        });
                    });
                }
            });
        },
        removeTemplate(id) {
            let thisVue = this;

            this.$swal({
                title: "Active Template",
                text: "This action is not reverse",
                type: "question",
                showCancelButton: true
            }).then((r) => {
                if (r && r.value) {
                    serviceReview.postRemoveTemplate(JSON.stringify({ id: id, secret:"123botup2019" }), function (res) {
                        if (!res || res.code != 200) {
                            thisVue.$swal({
                                title: "Remove template error",
                                type: "error",
                                showConfirmButton: false
                            });
                            return;
                        }

                        thisVue.getListTemplate();
                        thisVue.$swal({
                            title: "Remove template success",
                            type: "success",
                            showConfirmButton: false
                        });
                    });
                }
            });
        },
        getTemplateAndcategory: function () {

        },
        closePreview: function () {
            this.previewApp = 0;
        }
    },
    created() {
        this.getListCategory();
    },
    mounted: function () {

    }
};
